<template>
  <div class="BetaWelcomePage">
    <div class="BWPContainer">
      <Navbar />

      <!-- <div class="StepTip">
        <div class="StepTipImage"></div>

        <template v-if="step == 1">
          <span class="StepTipText"
            ><span class="BoldTipText">Давай познакомимся.</span> Заполни данные
            о себе, чтобы мы смогли узнать о твоих целях на нашем проекте.</span
          >
        </template>

        <template v-if="step == 2">
          <span class="StepTipText"
            ><span class="BoldTipText">Выбираем задачи. </span><br />1 шаг:
            Выбери и нажми на проект. Откроется список задач. <br />2 шаг:
            Выбери и нажми на задачу, чтобы узнать о ней подробнее. Ты можешь
            добавить несколько задач в Избранное, нажав на сердечко. <br />3
            шаг. Закрепи за собой приоритетную задачу, которую будешь выполнять.
            При необходимости, ты можешь её поменять.
          </span>
        </template>
      </div> -->

      <!-- 1 Этап регистрации -->
      <template v-if="step == 1">
        <div class="FillProfileData">
          <span class="Head">Заполните данные</span>
          <div class="Inputs">
            <div class="InputWrap">
              <span class="Label">Регион *</span>

              <el-select
                v-model="region_selected"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <el-option
                  v-for="item in region_list"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                  @click="setUniveristiesList(item.name)"
                />
              </el-select>

              <input
                v-if="region_selected == 'Другое'"
                v-model="region_other"
                type="text"
                placeholder="Укажите название"
              />
            </div>

            <div class="InputWrap">
              <span class="Label">Учебное заведение *</span>

              <el-select
                v-model="univesities_id"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <el-option
                  v-for="item in univesities_list"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>

              <input
                v-if="
                  univesities_list.find(
                    (obj) => obj.id == univesities_id && obj.name == 'Другое'
                  )
                "
                v-model="univesities_other"
                type="text"
                placeholder="Укажите название"
              />
            </div>

            <div class="InputWrap">
              <span class="Label">Курс</span>

              <input
                ref="course"
                type="text"
                @input="(event) => checkCourseText(event.target.value)"
                placeholder="Ваш курс в ВУЗе (1-5)"
              />
            </div>

            <!-- Откуда о нас узнали? -->
            <div class="InputWrap">
              <span class="Label">Откуда о нас узнали?</span>

              <el-select
                v-model="reg_source"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <template v-for="item in reg_source_list">
                  <el-option
                    v-if="
                      !(
                        item == 'Другое' &&
                        reg_source != '' &&
                        reg_source != 'Другое'
                      ) &&
                        !(
                          item != 'Другое' &&
                          reg_source != '' &&
                          reg_source == 'Другое'
                        )
                    "
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </template>
              </el-select>

              <input
                v-if="reg_source == 'Другое'"
                v-model="reg_source_other"
                type="text"
                placeholder="Укажите откуда"
              />
            </div>

            <!-- Цель регистрации -->
            <div class="InputWrap">
              <span class="Label">Цель регистрации</span>

              <el-select
                v-model="reg_goal"
                multiple
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <template v-for="item in reg_goal_list">
                  <el-option
                    v-if="
                      !(
                        item == 'Другое' &&
                        reg_goal != '' &&
                        reg_goal != 'Другое'
                      ) &&
                        !(
                          item != 'Другое' &&
                          reg_goal != '' &&
                          reg_goal == 'Другое'
                        )
                    "
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </template>
              </el-select>

              <input
                v-if="reg_goal == 'Другое'"
                v-model="reg_goal_other"
                type="text"
                placeholder="Укажите вашу цель"
              />
            </div>
          </div>
          <span class="Tip" v-if="Tip !== ''">{{ Tip }}</span>
          <GradientButton
            :style="
              reg_source.length != 0 ? '' : 'opacity: 0.5; cursor: default;'
            "
            class="FillButton noselect"
            ButtonText="Следующий шаг"
            @click.native="
              () => {
                if (reg_source.length != 0) {
                  nextBtn(1);
                }
              }
            "
          />
        </div>
      </template>

      <!-- 2 Этап регистрации -->
      <template v-if="step == 2">
        <div class="ProjectsAndTasks" v-show="step == 2">
          <!-- Проекты -->
          <div class="Projects">
            <span class="TemplateHeader">
              <span>1 шаг. Выбор проекта</span>
              <el-tooltip
                class="box-item"
                effect="customized"
                content="Выбери и нажми на проект. Откроется список задач."
                placement="top"
              >
                <CircleQuestionIcon />
              </el-tooltip>
            </span>

            <el-select
              style="margin-right: 9px;"
              v-model="sectorsSelected"
              class="RDSelectField"
              placeholder="Выберите направление"
              size="large"
              multiple
            >
              <el-option
                v-for="item in sectorsList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>

            <el-select
              v-if="sectorsSelected.length != 0"
              style="margin-right: 9px; margin-top: -20px;"
              v-model="sectionsSelected"
              class="RDSelectField"
              placeholder="Выберите специализацию"
              size="large"
              multiple
            >
              <el-option
                v-for="item in sectionsNameFromFilters"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>

            <div class="lds-dual-ring" v-if="ProjectsList.length === 0"></div>
            <div class="TemplateContainer" v-else>
              <!-- nProjectList -->
              <div
                v-for="n in getProjectsSorted"
                :key="`${n.project_type}${n.id}`"
                :class="{
                  ProjectCard: true,
                  SelectedCard:
                    (n.project_type == 'NProject' &&
                      nProjectSelected == n.id) ||
                    (n.project_type != 'NProject' && ProjectSelected == n.id),
                }"
                @click="
                  n.project_type == 'NProject'
                    ? chooseProject(n.id, true)
                    : chooseProject(n.id, false)
                "
              >
                <span
                  class="PCText"
                  lang="ru"
                  :style="
                    styleForCardHead(
                      n.project_have_prices == true,
                      jobsHaveHire(n.Jobs) == true
                    )
                  "
                  >{{ n.name }}
                </span>

                <div class="AdditionalIcons">
                  <template v-if="n.project_have_prices == true">
                    <el-tooltip class="box-item" effect="customized">
                      <template #content>
                        В данном проекте есть задачи, за выполнение которых<br />вы
                        сможете получить денежное вознаграждение
                      </template>

                      <RubleIcon />
                    </el-tooltip>
                  </template>

                  <template v-if="jobsHaveHire(n.Jobs) == true">
                    <el-tooltip class="box-item" effect="customized">
                      <template #content>
                        В данном проекте есть задачи, за выполнение которых<br />возможно
                        трудоустройство
                      </template>

                      <JobHireIcon />
                    </el-tooltip>
                  </template>
                </div>

                <div
                  class="TasksCounterAndUpdateDate"
                  v-if="n.name !== 'Свой проект'"
                >
                  <span
                    >{{ n.Jobs.length }}
                    {{ correctTaskListName(n.Jobs.length) }}</span
                  >
                  <div class="DotBlock"></div>
                  <span>Обновлено недавно</span>
                </div>
              </div>

              <!-- ProjectList -->
              <!-- <div
                :class="
                  `ProjectCard ${ProjectSelected == n.id ? 'SelectedCard' : ''}`
                "
                v-for="n in projectsSorted"
                :key="n.id"
                @click="chooseProject(n.id, false)"
              >
                <span
                  class="PCText"
                  :style="
                    styleForCardHead(
                      jobsHavePayment(n.Jobs) == true,
                      jobsHaveHire(n.Jobs) == true
                    )
                  "
                  v-if="n.name !== 'Свой проект'"
                  >{{ n.name }}
                </span>
                <span
                  class="PCText"
                  :style="
                    styleForCardHead(
                      jobsHavePayment(n.Jobs) == true,
                      jobsHaveHire(n.Jobs) == true
                    )
                  "
                  v-if="n.name === 'Свой проект'"
                  >Предложить свой проект
                </span>

                <div class="AdditionalIcons">
                  <template v-if="jobsHavePayment(n.Jobs) == true">
                    <el-tooltip class="box-item" effect="customized">
                      <template #content>
                        В данном проекте есть задачи, за выполнение которых<br />вы
                        сможете получить денежное вознаграждение
                      </template>

                      <RubleIcon />
                    </el-tooltip>
                  </template>

                  <template v-if="jobsHaveHire(n.Jobs) == true">
                    <el-tooltip class="box-item" effect="customized">
                      <template #content>
                        В данном проекте есть задачи, за выполнение которых<br />возможно
                        трудоустройство
                      </template>

                      <JobHireIcon />
                    </el-tooltip>
                  </template>
                </div>

                <div
                  class="TasksCounterAndUpdateDate"
                  v-if="n.name !== 'Свой проект'"
                >
                  <span
                    >{{ n.Jobs.length }}
                    {{ correctTaskListName(n.Jobs.length) }}</span
                  >
                  <div class="DotBlock"></div>
                  <span>Обновлено недавно</span>
                </div>
              </div> -->
            </div>
          </div>

          <!-- Таски -->
          <div
            class="ProjectTasks"
            v-if="ProjectSelectedName !== 'Свой проект'"
          >
            <span class="TemplateHeader">
              <span>2 шаг. Выбор задачи</span>
              <el-tooltip class="box-item" effect="customized" placement="top">
                <template v-slot:content>
                  Выбери и нажми на задачу, чтобы узнать о ней подробнее.<br />
                  Ты можешь добавить несколько задач в Избранное,<br />
                  нажав на сердечко.
                </template>
                <CircleQuestionIcon />
              </el-tooltip>
            </span>
            <div class="TemplateContainer">
              <!-- TasksList -->
              <div
                :class="
                  `TaskCard ${TaskSelected?.id == a.id ? 'SelectedCard' : ''}`
                "
                v-for="a in tasksFiltered"
                :key="`${a.id}${userFollowThisTask(a.id)}`"
                @click="TaskSelected = a"
              >
                <div class="TCHeader">
                  <div class="Upper">
                    <div class="Directions">
                      <span
                        v-for="(sect, sindx) in a.sections"
                        :key="`sects-${sindx}`"
                        lang="ru"
                        style="hyphens: auto; word-wrap: anywhere; word-break: break-word;"
                        >{{ sect.name }}</span
                      >
                    </div>
                    <div class="Difficulties">
                      <div
                        class="DifficultFilled"
                        v-for="x in getTaskDifficult(a.level)"
                        :key="x"
                      ></div>
                      <div
                        class="DifficultEmpty"
                        v-for="x in 3 - getTaskDifficult(a.level)"
                        :key="x"
                      ></div>
                    </div>

                    <div class="AdditionalIconsJobs">
                      <template v-if="a.price != null && a.price != 0">
                        <el-tooltip class="box-item" effect="customized">
                          <template #content>
                            За выполнение данной задачи вы сможете
                            <br />получить денежное вознаграждение
                          </template>
                          <RubleIcon />
                        </el-tooltip>
                      </template>

                      <template v-if="a.jobHire != null && a.jobHire != false">
                        <el-tooltip class="box-item" effect="customized">
                          <template #content>
                            Возможность трудоустройства
                          </template>
                          <JobHireIcon />
                        </el-tooltip>
                      </template>
                    </div>
                  </div>
                  <span>{{ a.name }}</span>
                </div>
                <div class="TCFooter">
                  <div class="TCFLeft HideOnMobile">
                    <div class="IconPeoples"></div>
                    <span
                      >{{
                        a?.subsListModified == null
                          ? 0
                          : a.subsListModified.length
                      }}
                      {{
                        correctTaskFollowersName(
                          a?.subsListModified == null
                            ? 0
                            : a.subsListModified.length
                        )
                      }}</span
                    >
                  </div>
                  <div class="AdditionalIconsJobs Mobile">
                    <template v-if="a.price != null && a.price != 0">
                      <el-tooltip class="box-item" effect="customized">
                        <template #content>
                          За выполнение данной задачи вы сможете
                          <br />получить денежное вознаграждение
                        </template>
                        <RubleIcon />
                      </el-tooltip>
                    </template>

                    <template v-if="a.jobHire != null && a.jobHire != false">
                      <el-tooltip class="box-item" effect="customized">
                        <template #content>
                          Возможность трудоустройства
                        </template>
                        <JobHireIcon />
                      </el-tooltip>
                    </template>
                  </div>

                  <template v-if="userFollowThisTask(a.id)">
                    <div
                      class="IconHeartFilled"
                      @click="clickTaskHeart(a.id)"
                    ></div>
                  </template>
                  <template v-else>
                    <div class="IconHeart" @click="clickTaskHeart(a.id)"></div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <!-- Инфо о таске -->
          <div class="TaskInfo">
            <template
              v-if="ProjectSelectedName !== 'Свой проект' && TaskSelected"
            >
              <div
                class="HeadAndDesc"
                :key="`${TaskSelected.name}${TaskSelected.description}`"
              >
                <span class="HDHead">{{ TaskSelected.name }}</span>
                <span
                  class="HDDesc ql-editor"
                  v-html="TaskSelected.description"
                ></span>
                <span
                  v-if="
                    TaskSelected?.purpose &&
                      TaskSelected.purpose !== '' &&
                      TaskSelected.purpose !== null
                  "
                  class="HDTitle"
                >
                  Цель задачи
                </span>
                <span
                  v-if="
                    TaskSelected?.purpose &&
                      TaskSelected.purpose !== '' &&
                      TaskSelected.purpose !== null
                  "
                  class="HDDesc"
                  >{{ TaskSelected.purpose }}
                </span>
                <span
                  v-if="
                    TaskSelected?.techStack &&
                      TaskSelected.techStack !== '' &&
                      TaskSelected.techStack !== null
                  "
                  class="HDTitle"
                >
                  Технологический стек
                </span>
                <span
                  v-if="
                    TaskSelected?.techStack &&
                      TaskSelected.techStack !== '' &&
                      TaskSelected.techStack !== null
                  "
                  class="HDDesc"
                  >{{ TaskSelected.techStack }}
                </span>
                <span
                  v-if="
                    TaskSelected?.integrations &&
                      TaskSelected.integrations !== '' &&
                      TaskSelected.integrations !== null
                  "
                  class="HDTitle"
                >
                  Интеграция: необходимость получения данных из других
                  систем</span
                >
                <span
                  v-if="
                    TaskSelected?.integrations &&
                      TaskSelected.integrations !== '' &&
                      TaskSelected.integrations !== null
                  "
                  class="HDDesc"
                  >{{ TaskSelected.integrations }}
                </span>
                <span
                  v-if="
                    TaskSelected?.result &&
                      TaskSelected.result !== '' &&
                      TaskSelected.result !== null
                  "
                  class="HDTitle"
                >
                  Желаемый результат
                </span>
                <span
                  v-if="
                    TaskSelected?.result &&
                      TaskSelected.result !== '' &&
                      TaskSelected.result !== null
                  "
                  class="HDDesc"
                  >{{ TaskSelected.result }}
                </span>
                <span
                  v-if="
                    TaskSelected?.effect &&
                      TaskSelected.effect !== '' &&
                      TaskSelected.effect !== null
                  "
                  class="HDTitle"
                >
                  Ожидаемый эффект
                </span>
                <span
                  v-if="
                    TaskSelected?.effect &&
                      TaskSelected.effect !== '' &&
                      TaskSelected.effect !== null
                  "
                  class="HDDesc"
                  >{{ TaskSelected.effect }}
                </span>
              </div>

              <div class="Competitions">
                <span class="CHead">Необходимые компетенции</span>
                <div class="ComptetionsContainer">
                  <Competence
                    :header="false"
                    :userLevels="
                      user.user_cells.length > 0
                        ? user.user_cells.map((cell) => cell.level_id)
                        : []
                    "
                    :useWhiteListRequireBlock="true"
                    :whiteListRequireBlock="getLevelsReq(TaskSelected.levels)"
                    :useAlternateMethod="true"
                    :competenceWhiteList="
                      getSectionsArray(TaskSelected.sections)
                    "
                    :viewMode="true"
                    :key="
                      `${TaskSelected.id}${getSectionsArray(
                        TaskSelected.sections
                      )}`
                    "
                  />
                </div>
              </div>

              <div class="ChooseTask">
                <div class="LeftBlock" lang="ru">
                  <span>Вы хотите выбрать эту задачу?</span>
                  <div class="Text">
                    <div class="Upper">
                      <span
                        >Добавьте задачу в список избранных -
                        <span class="SmallHeartIcon"></span>
                      </span>
                      <span
                        >и продолжайте выбор или закрепите задачу, тогда она
                        будет приоритетной в вашем профиле.</span
                      >
                    </div>
                    <span
                      >При необходимости, вы сможете поменять приоритетную
                      задачу и закрепить ее в профиле снова.</span
                    >
                  </div>
                </div>
                <span class="PinTask" @click="nextBtn(2)"
                  >Закрепить задачу</span
                >
              </div>

              <div
                class="TopRightButton"
                :key="
                  `${TaskSelected.id}${userFollowThisTask(TaskSelected.id)}`
                "
              >
                <template v-if="userFollowThisTask(TaskSelected.id)">
                  <div
                    class="IconHeartFilled"
                    @click="clickTaskHeart(TaskSelected.id)"
                  ></div>
                </template>
                <template v-else>
                  <div
                    class="IconHeart"
                    @click="clickTaskHeart(TaskSelected.id)"
                  ></div>
                </template>
              </div>
            </template>
            <template v-if="ProjectSelectedName === 'Свой проект'">
              <label class="LabelSelfProject" for="selfProjName"
                >Название проекта
                <input
                  class="InputSelfProject"
                  type="text"
                  v-model="SelfProjectInfo.name"
                  placeholder="Коротко опиши суть проекта"
                />
              </label>
              <label class="LabelSelfProject" for="selfProjName"
                >Описание проекта
                <textarea
                  class="InputSelfProject"
                  rows="5"
                  v-model="SelfProjectInfo.description"
                  placeholder="Подробно, шаг за шагом опиши проект"
                />
              </label>
              <label class="LabelSelfProject" for="selfProjName"
                >Результат
                <textarea
                  class="InputSelfProject"
                  rows="3"
                  v-model="SelfProjectInfo.result"
                  placeholder="Опиши желаемый результат"
                />
              </label>
              <label class="LabelSelfProject" for="selfProjName"
                >Ожидаемый эффект
                <textarea
                  class="InputSelfProject"
                  rows="3"
                  v-model="SelfProjectInfo.effect"
                  placeholder="Опиши ожидаемый эффект от реализации проекта"
                />
              </label>
              <div class="btns">
                <GradientButton
                  class="FillButton"
                  ButtonText="Отправить проект на модерацию"
                  @click.native="nextBtn(2)"
                />

                <ButtonGradientBorder
                  v-if="SelfProjectEdit"
                  class="FillButton"
                  ButtonText="Вернуться в профиль"
                  @click.native="redirectButton('/beta/profile')"
                />
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>

    <div
      class="FinishModalWrapper noselect"
      v-show="finishModal"
      :key="finishModal"
    >
      <div class="FinishModal">
        <div class="Text">
          <div class="Header">
            <span>{{
              this.ProjectSelectedName === "Свой проект"
                ? "Твой проект готов!"
                : "Задача закреплена в профиле!"
            }}</span>

            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="cursor: pointer;"
              @click="finishModal = false"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.599253 0.103391C0.349465 0.218662 0.192244 0.382023 0.0857442 0.637023C-0.0149761 0.877988 -0.0151305 1.04187 0.0850738 1.28458C0.140594 1.41921 1.36054 2.67331 4.17395 5.48806L8.18367 9.49969L4.15047 13.5374C-0.195467 17.8883 -0.0581641 17.7336 0.0262511 18.1836C0.0953416 18.552 0.448018 18.9047 0.81638 18.9737C1.26637 19.0582 1.11173 19.1955 5.46241 14.8497L9.5 10.8166L13.5376 14.8497C17.8883 19.1955 17.7336 19.0582 18.1836 18.9737C18.552 18.9047 18.9047 18.552 18.9737 18.1836C19.0582 17.7336 19.1955 17.8883 14.8497 13.5376L10.8166 9.5L14.8497 5.46241C19.1955 1.11173 19.0582 1.26637 18.9737 0.81638C18.9047 0.448018 18.552 0.0953416 18.1836 0.0262511C17.7336 -0.0581641 17.8883 -0.195467 13.5374 4.15047L9.49969 8.18367L5.48806 4.17395C2.67331 1.36054 1.41921 0.140594 1.28458 0.0850738C1.03418 -0.0183296 0.852395 -0.013428 0.599253 0.103391Z"
                fill="white"
              />
            </svg>
          </div>

          <span v-if="ProjectSelectedName !== 'Свой проект'"
            >Ваша приоритетная задача: {{ TaskSelected?.name }}. <br /><br />

            При необходимости, ты сможешь поменять приоритетную задачу у себя в
            профиле. <br /><br />

            Чтобы приступить к выполнению, необходимо получить новые
            компетенции: {{ TaskSelected?.section?.name }}. <br /><br />

            Тебе понравится, скорее переходи к следующему этапу!
          </span>
          <span v-if="ProjectSelectedName === 'Свой проект'">
            Проект {{ this.SelfProjectInfo.name }} отправлен на модерацию. О
            дальнейшем статусе проекта мы проинформируем тебя в почте и профиле.
          </span>
        </div>

        <div class="Buttons">
          <GradientButton
            ButtonText="Перейти в профиль"
            @click.native="redirectButton('/beta/profile')"
          />

          <span class="CloseLater" @click="finishModal = false"
            >Я ещё подумаю</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RubleIcon from "@/components/CustomElements/RubleIcon.vue";
import JobHireIcon from "@/components/CustomElements/Icons/JobHireIcon.vue";

import Navbar from "@/components/Navbar/NavbarCombined.vue";
import GradientButton from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

import Competence from "@/components/Profile/CompetenceBlock.vue";
import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";

import { getAllProject } from "@/api/project.js";
import { mapGetters, mapMutations } from "vuex";

import { logout, refresh } from "@/api/auth.js";

import {
  getAllDifficulties,
  addFollowersJob,
  removeFollowersJob,
  getLatestFollowersJob,
  pinTask,
  createJob,
  findAllJobByUser,
} from "@/api/job.js";

import {
  update,
  findById,
  getRegionAndUniver,
  getRegionAndUniverList,
  getUserRegionAndUniver,
} from "@/api/user.js";

import {
  addUserGoal,
  addUserSource,
  sendInfoDiscord,
  getUserRegistrationFields,
} from "@/api/registration_info.js";

import { getSections, getSectorsWithSections } from "@/api/competence.js";

import { getApprovedProjectsWithTasks } from "@/api/n_project.js";

export default {
  components: {
    Navbar,
    GradientButton,
    Competence,
    ButtonGradientBorder,
    RubleIcon,
    CircleQuestionIcon,
    JobHireIcon,
  },
  name: "BetaWelcomePage",
  data() {
    return {
      step: 1,

      // Step1:
      course: null,

      region_list: [],
      region_selected: "",
      region_other: "",

      univesities_list: [],
      univesities_id: "",
      univesities_other: "",

      reg_source_list: [
        "Мероприятие",
        "Институт",
        "Преподаватели",
        "Реклама",
        "Друзья",
        "Соц.сети (tg, vk и т.д.)",
        "Другое",
      ],
      reg_source: [],
      reg_source_other: "",

      reg_goal_list: [
        "Диплом",
        "Практика",
        "Развитие своего проекта",
        "Повышение своих компетенций",
        "Другое",
      ],
      reg_goal: [],
      reg_goal_other: "",

      // Step2:
      finishModal: false,

      Difficulties: [],

      ProjectsList: [],
      ProjectSelected: null,
      ProjectSelectedName: null,
      ProjectsLoading: true,

      nProjectsList: [],
      nProjectSelected: null,
      nProjectSelectedName: null,

      TasksList: [],
      TaskSelected: null,
      TasksLoading: false,

      nTasksList: [],
      nTaskSelected: null,

      MountedSelectProj: null,
      MountedSelectNProj: null,
      DOMReady: false,

      SelfProjectInfo: {
        name: "",
        description: "",
        result: "",
        effect: "",
        id: null,
      },
      SelfProjectEdit: false,
      Tip: "",

      sectorsSelected: [],
      sectorsList: [],

      sectionsSelected: [],
      sectionsList: [],
    };
  },
  watch: {
    sectorsSelected: {
      handler(val) {
        this.ProjectSelected = null;
        this.nProjectSelected = null;

        this.TaskSelected = null;
        this.nTaskSelected = null;
        this.TasksList = null;
        this.nTasksList = null;

        if (val == null || val.length == 0) {
          this.sectionsSelected = [];
        } else {
          this.sectionsSelected = this.sectionsSelected.filter((item) =>
            this.sectionsNameFromFilters.includes(item)
          );
        }
      },
    },
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    regionSelected() {
      return (
        (this.region_selected === "") |
        (this.region_selected === "Другое" && this.region_other === "")
      );
    },

    universitySelected() {
      const other_uni_id = this.univesities_list.find(
        (obj) => obj.id === this.univesities_id && obj.name === "Другое"
      );
      return (
        (this.univesities_id === "") |
        (this.univesities_id === other_uni_id && this.univesities_other === "")
      );
    },

    projectsSorted() {
      return this.filterProjects(this.ProjectsList);
    },

    nProjectsSorted() {
      return this.filterProjects(this.nProjectsList);
    },

    getProjectsSorted() {
      let projects = this.projectsSorted;
      projects = projects.map((x) => {
        let havePriceJobs = this.jobsHavePayment(x.Jobs);
        return {
          ...x,
          project_type: "DefaultProject",
          project_have_prices: havePriceJobs,
        };
      });

      let nProjects = this.nProjectsSorted;
      nProjects = nProjects.map((x) => {
        let havePriceJobs = this.jobsHavePayment(x.Jobs);
        return {
          ...x,
          project_type: "NProject",
          project_have_prices: havePriceJobs,
        };
      });

      return [...projects, ...nProjects].sort((a, b) => {
        if (
          a.project_have_prices !== false &&
          b.project_have_prices === false
        ) {
          return -1;
        } else if (
          a.project_have_prices === false &&
          b.project_have_prices !== false
        ) {
          return 1;
        } else if (
          a.project_type === "NProject" &&
          b.project_type !== "NProject"
        ) {
          return -1;
        } else if (
          a.project_type !== "NProject" &&
          b.project_type === "NProject"
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    },

    sectionsNameFromFilters() {
      if (this.sectorsSelected.length == 0) {
        return [];
      }

      let sectionsNames = this.sectorsSelected
        .map((sector) => {
          let _sections_names_list = this.sectorsList.find(
            (_sector) => sector == _sector.name
          );

          _sections_names_list = _sections_names_list.sections.map(
            (x) => x.name
          );

          return _sections_names_list;
        })
        .reduce((acc, val) => acc.concat(val), []);

      return sectionsNames;
    },

    sectionsNameFiltered() {
      if (this.sectorsSelected.length == 0) {
        return [];
      } else {
        if (this.sectionsSelected.length == 0) {
          return this.sectionsNameFromFilters;
        } else {
          return this.sectionsSelected;
        }
      }
    },

    tasksFiltered() {
      if (this.nProjectSelected == null && this.ProjectSelected == null) {
        return [];
      }

      let Project = null;

      if (this.ProjectSelected != null) {
        Project = this.ProjectsList.find((obj) => {
          return obj.id === this.ProjectSelected;
        });
      }

      if (this.nProjectSelected != null) {
        Project = this.nProjectsList.find((obj) => {
          return obj.id === this.nProjectSelected;
        });
      }

      if (Project == null) {
        return [];
      }

      this.loadingTasks(Project.Jobs, false);

      return Project.Jobs.filter((job) =>
        this.sectionsNameFiltered.length == 0
          ? true
          : job?.sections.some((x) =>
              this.sectionsNameFiltered.includes(x.name)
            )
      );
    },
  },
  async created() {
    await getRegionAndUniverList().then((response) => {
      this.region_list = response.data;
    });

    this.getUniverAndRegion();

    this.getUserRegData(this.user.id);

    getApprovedProjectsWithTasks().then((res) => {
      this.nProjectsList = res.data;
    });

    if (this.$route.query?.skipRegister == "true") {
      this.step = 2;
    }

    findAllJobByUser(this.user.id).then((res) => {
      if (res.data.length > 0) {
        this.SelfProjectEdit = true;
        const selfProject = res.data[0];
        this.SelfProjectInfo.name = selfProject.name;
        this.SelfProjectInfo.description = selfProject.description;
        this.SelfProjectInfo.result = selfProject.result;
        this.SelfProjectInfo.effect = selfProject.effect;
        this.SelfProjectInfo.id = selfProject.id;
      }
    });
    getAllProject().then((response) => {
      this.ProjectsList = response.data.filter((proj) => {
        if (proj?.name === "Свой проект") {
          return true;
        }

        if (
          proj?.Jobs?.length != 0 &&
          proj.Jobs.every((job) => job.sectionId !== null)
        ) {
          return true;
        }

        return false;
      });

      this.ProjectsLoading = false;
    });

    getAllDifficulties().then((response) => {
      this.Difficulties = response.data;
    });

    getSectorsWithSections().then((response) => {
      this.sectorsList = response.data;
    });
  },
  updated() {
    if (!this.DOMReady) {
      if (this.$route.query?.selectProjectByID != null) {
        if (parseInt(this.$route.query?.nProject) == 1) {
          this.MountedSelectNProj = parseInt(
            this.$route.query.selectProjectByID
          );
        } else {
          this.MountedSelectProj = parseInt(
            this.$route.query.selectProjectByID
          );
        }
      }

      if (this.MountedSelectProj != null) {
        this.chooseProject(parseInt(this.MountedSelectProj), false);
      }
      if (this.MountedSelectNProj != null) {
        this.chooseProject(parseInt(this.MountedSelectNProj), true);
      }

      this.DOMReady = true;
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    getLevelsReq(lvls) {
      return lvls.map((x) => x.id);
    },

    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },

    getSectionsArray(arr) {
      return arr.map((x) => x.id);
    },
    jobsHavePayment(project_jobs) {
      return project_jobs.some(
        (project_job) => project_job.price !== null && project_job.price !== 0
      );
    },

    jobsHaveHire(project_jobs) {
      return project_jobs.some(
        (project_job) =>
          project_job.jobHire != null && project_job.jobHire !== false
      );
    },

    styleForCardHead(HavePayment, HaveHire) {
      if (HavePayment && HaveHire) {
        return "width: calc(100% - 60px);";
      }

      if (HavePayment || HaveHire) {
        return "width: calc(100% - 24px);";
      }

      return "";
    },

    filterProjects(project_list) {
      if (this.sectionsNameFiltered.length === 0) {
        return project_list;
      }

      return project_list
        .map((project) => {
          let jobs = project.Jobs.filter((job) =>
            job?.sections.some((x) =>
              this.sectionsNameFiltered.includes(x.name)
            )
          );

          jobs = jobs == null ? [] : jobs;

          return { ...project, Jobs: jobs };
        })
        .filter((project) => project.Jobs.length > 0);
    },

    checkCourseText(text) {
      text = text.replace(/[^\d]/g, "");

      if (text == "") {
        this.course = "";
        this.$refs.course.value = "";
      }

      if (text.length > 1) {
        text = text.substr(text.length - 1, 1);
      }

      if (
        String(text) === "1" ||
        String(text) === "2" ||
        String(text) === "3" ||
        String(text) === "4" ||
        String(text) === "0" ||
        String(text) === "5"
      ) {
        this.course = text;
        this.$refs.course.value = text;
      } else {
        this.course = "";
        this.$refs.course.value = "";
      }
    },
    getValidCourseValue(val) {
      if (isNaN(parseInt(val, 10)) == true) {
        return 0;
      } else {
        return parseInt(val, 10);
      }
    },

    async getUserRegData(__userID) {
      let res = null;

      try {
        res = await getUserRegistrationFields(__userID);
      } catch (error) {
        return;
      }

      if (res != null) {
        this.course = res.data.lvl == null ? "" : res.data.lvl;
        if (this.$refs?.course != null) {
          this.$refs.course.value = res.data.lvl == null ? "" : res.data.lvl;
        }

        if (res.data.goals.length > 0) {
          this.reg_goal = [];
          for (let obj of res.data.goals) {
            this.reg_goal.push(obj.type_value);
            if (obj.type_value == "Другое") {
              this.reg_goal_other = obj.other_value;
            }
          }
        }

        if (res.data.sources.length > 0) {
          this.reg_source = [];
          if (typeof res.data.sources === "string") {
            this.reg_source.push(res.data.sources);
          } else {
            for (let obj of res.data.sources) {
              this.reg_source.push(obj.type_value);
              if (obj.type_value == "Другое") {
                this.reg_source_other = obj.other_value;
              }
            }
          }
        }
      }
    },

    async getUniverAndRegion() {
      await getRegionAndUniver(this.user.id).then((response) => {
        let MapUniver = response?.data?.MapRegion;

        if (MapUniver == null || MapUniver?.UsersChoosedUniver == null) {
          return 0;
        }

        this.region_selected = MapUniver.UsersChoosedUniver.region.name;
        this.region_other =
          this.region_selected == "Другое" ? MapUniver.region_other : "";

        this.setUniveristiesList(MapUniver.UsersChoosedUniver.region.name);

        this.univesities_id = MapUniver.UsersChoosedUniver.id;
        this.univesities_other =
          MapUniver.UsersChoosedUniver.name == "Другое"
            ? MapUniver.univer_other
            : "";
      });
    },
    setUniveristiesList(selectedRegion) {
      this.univesities_list = [];
      this.univesities_id = "";

      let result = this.region_list.find((obj) => obj.name == selectedRegion);
      this.univesities_list = [...result.univers];
    },

    async sendUserRegData() {
      await addUserGoal({
        userID: this.user.id,
        goal: this.reg_goal,
        goal_other: this.reg_goal_other,
      });

      let _source =
        typeof this.reg_source === "string"
          ? [this.reg_source]
          : this.reg_source;

      console.log(_source);

      await addUserSource({
        userID: this.user.id,
        source: _source,
        source_other: this.reg_source_other,
      });

      if (this.registration_success == false) {
        this.registration_success = true;

        // await sendInfoDiscord({
        //   u_id: this.user.id,
        //   u_f: this.user.surname,
        //   u_i: this.user.name,
        //   u_o: this.user.patronymic,
        //   u_email: this.user.email,
        //   u_phone: this.user.tel,
        //   u_tg: this.user.tg_nick,
        //   u_city: this.city,
        //   u_univer: this.institute,
        //   u_direction: this.direction,
        //   u_course: this.course,
        //   u_goal: this.reg_goal,
        //   u_goal_other: this.reg_goal_other,
        //   u_source: this.reg_source,
        //   u_source_other: this.reg_source_other,
        // });
      }
    },

    async nextBtn(stepID) {
      if (stepID == 1) {
        if (this.regionSelected === 1) {
          this.Tip = "Укажите регион и учебное заведение";
        } else if (this.regionSelected === 0 && this.universitySelected === 1) {
          this.Tip = "Укажите учебное заведение";
        } else {
          this.Tip = "";
          if (this.region_selected != "Другое") {
            this.region_other = "";
          }

          await update(this.user.id, {
            university_model_id: this.univesities_id,
            university_model_other: this.univesities_other,
            region_model_other: this.region_other,
            lvl: this.getValidCourseValue(this.course),
          });

          await this.sendUserRegData();

          this.step = 2;
        }
      } else if (stepID == 2) {
        if (this.ProjectSelectedName === "Свой проект") {
          const data = {
            authorId: this.user.id,
            name: this.SelfProjectInfo.name,
            description: this.SelfProjectInfo.description,
            result: this.SelfProjectInfo.result,
            effect: this.SelfProjectInfo.effect,
            levels: [],
            sections: [],
            level: null,
            projectId: this.ProjectSelected,
            status: "moderation",
            order: 0,
          };
          if (this.SelfProjectInfo.id !== null)
            data.id = this.SelfProjectInfo.id;

          let createdJobId = 0;
          let res = await createJob(data);
          createdJobId = res.data.id;
          await pinTask(createdJobId, { user_id: this.user.id });
        } else {
          await pinTask(this.TaskSelected.id, { user_id: this.user.id });
        }

        await this.updateUser();
        this.finishModal = true;
      }
    },

    correctTaskListName(tasksCounter) {
      if (tasksCounter % 10 == 1 && tasksCounter != 11) {
        return "задача";
      }
      if (
        tasksCounter % 10 >= 2 &&
        tasksCounter % 10 <= 4 &&
        (tasksCounter <= 11 || tasksCounter >= 15)
      ) {
        return "задачи";
      }

      return "задач";
    },
    correctTaskFollowersName(tasksCounter) {
      if (tasksCounter % 10 == 1 && tasksCounter != 11) {
        return "следит";
      }

      return "следят";
    },
    userFollowThisTask(TaskID) {
      let Task = this.TasksList.find((obj) => {
        return obj.id === TaskID;
      });

      if (Task == null) {
        return 0;
      }

      let FindFollow = Task.subsListModified.find((user) => {
        return String(user.id) === String(this.user.id);
      });

      if (FindFollow == null) {
        return false;
      } else {
        return true;
      }
    },
    async updateTaskFollowers(taskID) {
      this.TasksLoading = true;
      let subs = await this.getTaskFollowers(taskID);
      let Task = this.TasksList.find((obj) => {
        return obj.id === taskID;
      });
      Task.subsListModified = subs.data.JobFollowers;

      this.TasksLoading = false;
    },

    chooseProject(projID, isNProject) {
      this.TaskSelected = null;

      if (isNProject) {
        this.nProjectSelected = projID;
        this.ProjectSelected = null;

        this.TasksList = [];
        this.TasksLoading = true;

        let Project = this.nProjectsList.find((obj) => {
          return obj.id === projID;
        });

        if (Project == null) {
          return 0;
        }

        this.ProjectSelectedName = Project.name;

        this.loadingTasks(Project.Jobs, true);
      } else {
        this.ProjectSelected = projID;
        this.nProjectSelected = null;

        this.TasksList = [];
        this.TasksLoading = true;

        let Project = this.ProjectsList.find((obj) => {
          return obj.id === projID;
        });

        if (Project == null) {
          return 0;
        }

        this.ProjectSelectedName = Project.name;

        this.loadingTasks(Project.Jobs, false);
      }
    },
    async loadingTasks(TaksRawList, isNProject) {
      let RawList = TaksRawList;
      for (let i of RawList) {
        if (isNProject) {
          i.subsListModified = i.JobFollowers;
          continue;
        }

        let subs = await this.getTaskFollowers(i.id);
        i.subsListModified = subs.data.JobFollowers;
      }
      this.TasksList = RawList;
      this.TasksLoading = false;
    },

    getTaskDifficult(DiffName) {
      let indx = this.Difficulties.findIndex((obj) => {
        return obj.name === DiffName;
      });

      return indx + 1;
    },
    getTaskCompBlockReq() {
      let blockReq = this.getTaskDifficult(this.TaskSelected.level) * 3;
      let res = [];
      res.push([]);

      for (let i = 0; i < blockReq; i++) {
        res[0][i] = true;
      }

      return res;
    },

    async clickTaskHeart(taskID) {
      if (this.userFollowThisTask(taskID)) {
        await this.unfollowTask(taskID);
      } else {
        await this.followTask(taskID);
      }

      await this.updateTaskFollowers(taskID);
    },
    async followTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await addFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async unfollowTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await removeFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async getTaskFollowers(taskID) {
      return await getLatestFollowersJob(taskID);
    },

    redirectButton(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
@import "./../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.Tip {
  color: #fff;
  font-size: 0.9rem;
  margin-left: 6px;
}
.btns {
  display: flex;
}
.btns div {
  margin-right: 0.5rem !important;
}
.LabelSelfProject {
  color: #e8e7ec;
  font-feature-settings: "calt" off;
  font-family: Nunito Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.0175rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  margin-bottom: 0.25rem;
}
.InputSelfProject {
  border-radius: 0.25rem;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );
  backdrop-filter: blur(12.253599166870117px);

  display: flex;
  width: 100%;
  padding: 0.8125rem 0.75rem 0.75rem 0.75rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #fff;
}
.InputSelfProject::placeholder {
  color: #413e74;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 1.45313rem */
}

.FillProfileData {
  position: relative;

  width: fit-content;
  min-width: 428px;

  height: fit-content;
  max-height: 100%;

  padding: 30px;
  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;
  overflow: hidden;
}
.FillProfileData > .Head {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.FillProfileData > .FillButton {
  width: 100%;
}

.FillProfileData > .Inputs {
  position: relative;

  width: 100%;
  height: fit-content;
  max-height: 100%;

  padding: 0px 9px 6px 6px;

  padding-right: 9px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  flex-shrink: 1;
  overflow: auto;
}
.FillProfileData > .Inputs > .InputWrap {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.FillProfileData > .Inputs > .InputWrap > .Label {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}

.FillProfileData > .Inputs > .InputWrap > select,
.FillProfileData > .Inputs > .InputWrap > input {
  position: relative;

  width: 100%;
  height: fit-content;

  padding: 12px;

  border-radius: 4px;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgb(41, 37, 88) 4.06%,
    rgb(38, 35, 83) 42.35%,
    rgb(40, 38, 89) 99.53%
  );
  background-color: rgb(37, 31, 76);
  backdrop-filter: blur(12px);

  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}

.FillProfileData > .Inputs > .InputWrap > select:focus,
.FillProfileData > .Inputs > .InputWrap > input:focus-visible {
  outline: 2px solid rgb(130, 111, 238);
  border-radius: 3px;
}

option {
  color: #fff;

  font: -moz-pull-down-menu;

  -moz-font-family: "Montserrat";
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */

  background-color: transparent;
}

select option:hover {
  background-color: #36317a;
}
</style>

<style scoped>
.BetaWelcomePage {
  position: relative;

  display: flex;

  width: 100%;
  height: 100vh;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  ) !important;
  background-position: 50% 50% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: auto 120% !important;
}
.BetaWelcomePage > .BWPContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 100px;

  padding: 50px 120px 32px 120px;

  width: 100%;
  height: 100vh;
}

.Navbar {
  position: relative;
  display: block;

  width: 100%;
  height: 33px;

  background-color: rgba(0, 0, 0, 0.25);

  flex-shrink: 0;
}
.StepTip {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;

  width: fit-content;
  height: fit-content;

  padding: 20px 30px;
  margin: 0% auto;

  border-radius: 20px;
  background: linear-gradient(91deg, #1e768e 2.08%, #9652d4 98.48%);
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);

  flex-shrink: 0;
}
.StepTip > * {
  margin-top: auto;
  margin-bottom: auto;
}
.StepTip > .StepTipImage {
  position: relative;
  display: block;

  width: 48px;
  height: 48px;

  background: url("./../assets/img/Landing/LogoWhiteBG48x48.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.StepTip > .StepTipText {
  position: relative;
  display: block;

  width: auto;
  max-width: 588px;
  height: fit-content;

  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
.StepTip > .StepTipText > .BoldTipText {
  font-weight: 700;
}

.ProjectsAndTasks {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  margin: 0% auto;

  width: 100%;
  max-width: 1680px;

  height: 100%;

  overflow: hidden;

  flex-shrink: 1;
}
.ProjectsAndTasks > .Projects {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  padding: 30px 20px 20px 20px;

  width: 405px;
  height: 100%;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;

  overflow: hidden;
}
.ProjectsAndTasks > .ProjectTasks {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  padding: 30px 20px 20px 20px;

  width: 405px;
  height: 100%;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;

  overflow: hidden;
}
.ProjectsAndTasks > .TaskInfo {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;

  padding: 30px;

  width: 830px;
  height: 100%;

  border-radius: var(--o-s-global-border-radius);
  background: rgba(31, 30, 72, 0.6);

  flex-shrink: 1;

  overflow-y: auto;
}

.ProjectsAndTasks > .Projects > .TemplateHeader,
.ProjectsAndTasks > .ProjectTasks > .TemplateHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectsAndTasks > .Projects > .TemplateHeader > *,
.ProjectsAndTasks > .ProjectTasks > .TemplateHeader > * {
  margin: auto 0%;
}

.ProjectsAndTasks > .Projects > .TemplateDesc,
.ProjectsAndTasks > .ProjectTasks > .TemplateDesc {
  position: relative;

  margin-bottom: 22px;

  color: #fff;

  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProjectsAndTasks > .Projects > .TemplateContainer,
.ProjectsAndTasks > .ProjectTasks > .TemplateContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: 100%;
  height: auto;

  padding-right: 9px;

  overflow-y: auto;
}

.ProjectsAndTasks > .Projects .ProjectCard.SelectedCard {
  background: linear-gradient(98deg, #1f1e48 0%, #494888 100%);
}
.ProjectsAndTasks > .Projects .ProjectCard {
  position: relative;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  min-height: 142px;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  background: #272655;

  height: auto;
  flex-shrink: 0;
}
.ProjectsAndTasks > .Projects .ProjectCard > .PCText {
  position: relative;
  display: block;

  color: #fff;

  width: 100%;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;

  hyphens: auto;
}
.ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate > span {
  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard.SelectedCard {
  background: linear-gradient(98deg, #1f1e48 0%, #494888 100%);
}
.ProjectsAndTasks > .ProjectTasks .TaskCard {
  position: relative;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  min-height: 180px;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  background: #272655;

  height: auto;
  flex-basis: auto;
  flex-shrink: 0;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > *,
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > .TCFLeft > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > .TCFLeft {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter > .TCFLeft > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCFooter {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ProjectsAndTasks > .ProjectTasks .TaskCard > .TCHeader {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.TCHeader > span {
  position: relative;
  display: block;

  width: auto;
  height: fit-content;

  color: #fff;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.TCHeader > .Upper {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  overflow: hidden;
  flex-wrap: wrap;
}
.TCHeader > .Upper > *:not(.RubbleIconStyle) {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;
}
.TCHeader > .Upper > .Directions {
  gap: 5px;
}
.TCHeader > .Upper > .Directions > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #1d1a42;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 4px 8px;

  border-radius: 4px;
  background: #1dd185;
}

.DotBlock {
  position: relative;
  display: block;

  width: 3px;
  height: 3px;

  border-radius: 7px;
  background: #fff;
}
.IconPeoples {
  position: relative;
  display: block;

  width: 16px;
  height: 12px;

  background: url("./../assets/img/Landing/Peoples16x12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.IconHeart {
  position: relative;
  display: block;

  width: 20px;
  height: 18px;

  background: url("./../assets/img/Landing/HeartPurple.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: drop-shadow(0px 0px 4px #ffffff60);
}
.SmallHeartIcon {
  position: relative;
  display: block;

  width: 15px;
  height: 14px;

  margin-left: 3px;

  background: url("./../assets/img/Landing/HeartPurple15x14.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.IconHeartFilled {
  position: relative;
  display: block;

  width: 20px;
  height: 18px;

  background: url("./../assets/img/Landing/HeartWhite.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: drop-shadow(0px 0px 4px #fff);
}

.DifficultEmpty {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.DifficultFilled {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ProjectsAndTasks > .TaskInfo > .HeadAndDesc {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
.ProjectsAndTasks > .TaskInfo > .HeadAndDesc > .HDHead {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  padding-right: 120px;

  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ProjectsAndTasks > .TaskInfo > .HeadAndDesc > .HDDesc {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  padding-right: 120px;

  color: #fff;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.ProjectsAndTasks > .TaskInfo > .HeadAndDesc > .HDTitle {
  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ProjectsAndTasks > .TaskInfo > .TopRightButton {
  position: absolute;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  top: 30px;
  right: 30px;

  z-index: 10;
}

.ProjectsAndTasks > .TaskInfo > .Competitions {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
.ProjectsAndTasks > .TaskInfo > .Competitions > .CHead {
  color: #fff;

  /* h3 */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ProjectsAndTasks > .TaskInfo > .ChooseTask {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;

  margin-top: auto;

  padding: 20px 30px;

  border-radius: var(--o-s-global-border-radius);
  background: #292558;
}

.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fafafa;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock > .Text {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.ProjectsAndTasks > .TaskInfo > .ChooseTask > .LeftBlock > .Text span {
  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ChooseTask > .LeftBlock > .Text > .Upper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ChooseTask > .LeftBlock > .Text > .Upper > span {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ChooseTask > .LeftBlock > .Text > .Upper > span > * {
  margin-top: auto;
  margin-bottom: auto;
}

.PinTask {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 10px 20px;
  margin: auto 0%;

  width: fit-content;
  height: fit-content;

  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;

  border-radius: 4px;
  background: #413e74;

  flex-shrink: 0;
}
</style>

<style scoped>
.AdditionalIconsJobs.Mobile {
  display: none;
}

.AdditionalIconsJobs {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  margin-left: auto;

  width: fit-content;
  height: fit-content;
}

.AdditionalIcons {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  top: 20px;
  right: 20px;

  width: fit-content;
  height: fit-content;
}
.RubbleIconStyle {
  margin-left: auto;
}

.FinishModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
}
.FinishModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;

  max-width: 583px;
  max-height: 90%;

  overflow-y: auto;

  padding: 48px;
  margin: auto;

  border-radius: 20px;
  background: linear-gradient(
      114deg,
      rgba(0, 234, 255, 0.2) 0%,
      rgba(29, 26, 66, 0.2) 48.44%,
      rgba(68, 0, 255, 0.2) 100%
    ),
    #1d1a42;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.FinishModal::-webkit-scrollbar {
  width: 0px;
}
.FinishModal::-webkit-scrollbar-track {
  background: transparent;
}
.FinishModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.FinishModal > .Buttons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  margin-top: 10px;
}
.FinishModal > .Buttons > * {
  margin-top: auto;
  margin-bottom: auto;
}
.FinishModal > .Buttons > .CloseLater {
  position: relative;
  display: block;

  color: #fff;
  text-align: center;

  cursor: pointer;

  /* text */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.FinishModal > .Text {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.FinishModal > .Text > .Header {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.FinishModal > .Text > .Header > * {
  margin-top: auto;
  margin-bottom: auto;
}
.FinishModal > .Text > .Header > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.FinishModal > .Text > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
</style>

<style scoped>
@media (max-width: 1500px) {
  .BetaWelcomePage {
    height: fit-content;
    overflow-y: auto !important;
  }
  .BWPContainer {
    height: auto !important;
    width: 80% !important;
    margin: auto;
  }

  .ProjectsAndTasks {
    flex-direction: column;
  }
  .ProjectsAndTasks > div {
    width: 100% !important;
    height: 500px !important;
  }
  .ProjectsAndTasks > div > .TemplateContainer {
    height: 500px !important;
  }
  .ChooseTask {
    flex-wrap: wrap;
  }
  .ChooseTask > .LeftBlock {
    hyphens: auto;
  }
}
@media (max-width: 1200px) {
  .StepTipImage {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .BWPContainer {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
@media (max-width: 640px) {
  .BWPContainer {
    width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .SmallHeartIcon {
    display: none;
  }
  .FinishModal > .Buttons {
    flex-wrap: wrap;
  }
  .FinishModal {
    margin: 20px;
  }
}

@media (max-width: 480px) {
  .HideOnMobile,
  .AdditionalIconsJobs:not(.Mobile) {
    display: none !important;
  }

  .AdditionalIconsJobs.Mobile {
    display: flex;
    margin-left: 0;
  }

  .BetaWelcomePage > .BWPContainer {
    padding: 24px 60px;
    height: 100svh;
  }

  .FillProfileData {
    width: 100%;
    min-width: 0px;
  }

  .ProjectsAndTasks > .Projects .ProjectCard > .TasksCounterAndUpdateDate {
    flex-direction: column;
    gap: 10px;
  }

  .DotBlock {
    display: none;
  }

  .ProjectsAndTasks > .TaskInfo {
    padding: 0px;
  }

  .ProjectsAndTasks > .TaskInfo > .HeadAndDesc {
    padding: 0% 20px;
    padding-top: 20px;
  }

  .ProjectsAndTasks > .TaskInfo > .Competitions {
    padding: 0% 20px;
  }
  .ProjectsAndTasks > .TaskInfo > .ChooseTask {
    padding: 15px 20px;
  }
}
</style>
